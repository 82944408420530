.react-tooltip-tolq {
  margin-top: 4px !important;
  border-radius: 6px !important;
  padding: 6px !important;
  font-size: 10px !important;
  font-weight: 500;
  background-color: theme('colors.secondary.black')  !important;
  z-index: 999999999999;
  box-shadow: 0 8px 8px -8px rgba(36,92,144,0.17);
  opacity: 1 !important;

  & > .react-tooltip-arrow {
    bottom: -3px !important;
    border-radius: 2px !important;
  }
}

.react-tooltip-tolq-info {
  margin-top: 0px !important;
  border: solid 1px theme('colors.tertiary.400');
  border-radius: 6px !important;
  padding: 10px !important;
  font-size: 12px !important;
  font-weight: 500;
  background-color: theme('colors.primary.blue.900')  !important;
  color: theme('colors.tertiary.100')  !important;
  z-index: 999999999999;
  box-shadow: 0 8px 8px -8px rgba(36,92,144,0.17);
  opacity: 1 !important;

  & > .react-tooltip-arrow {
    bottom: -5px !important;
    border-bottom: solid 1px theme('colors.tertiary.400');
    border-right: solid 1px theme('colors.tertiary.400');
    border-radius: 2px !important;
  }
}