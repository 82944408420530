@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: Jost, Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Source Sans Pro, sans-serif;
  display: flex;
  flex-direction: column;
  flex: 1;
  overscroll-behavior: none;
  @apply bg-tertiary-900;
}

a {

  text-decoration: none;
  color: theme('colors.secondary.link') !important;
  @apply font-normal;
}

* {
  box-sizing: border-box;
  scrollbar-color: theme('colors.tertiary.600') transparent;
}

a:focus-visible {
  @apply m-1 rounded-3xl p-1 outline outline-1 outline-primary-blue-200;
}

*::-webkit-scrollbar {
  scrollbar-color: theme('colors.tertiary.600') transparent;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: theme('colors.tertiary.600');
  border-radius: 100px;
}

b {
  font-weight: 600 !important;
}

body>div:nth-child(2) {
  display: flex;
  flex-direction: column;
  flex: 1;
}

div:has(.graphiql-container) {
  height: 100vh !important;
  min-height: 100vh !important;
}

@layer base {
  [data-nextjs-scroll-focus-boundary]:not(:has(.graphiql-container)) {
    display: contents;
  }
}