.wobble {
  animation: wobble 1.2s linear infinite;
  transform-origin: 50% 100%;
}

@keyframes wobble {
  0% {
    transform: none;
  }

  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, -1, -15deg);
  }

  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, -1, 15deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, -1, -15deg);
  }

  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, -1, 10deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, -1, -5deg);
  }

  100% {
    transform: none;
  }
}
