:root {
  --toastify-color-info: theme('colors.primary.blue.100');
  --toastify-color-success: theme('colors.primary.green.100');
  --toastify-color-warning: theme('colors.primary.orange.100');
  --toastify-color-error: theme('colors.primary.red.100');

  --toastify-toast-width: 348px;
  --toastify-toast-min-height: 88px;
  --toastify-z-index: 9999;

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-icon {
  @apply me-4 flex h-12 shrink-0 border-e border-solid border-t-tertiary-600 pe-4;

  width: 58px !important;
}

.Toastify__toast-body {
  @apply py-0 pl-[8px] pr-[6px];
}

.Toastify__toast-body > div:last-child {
  @apply font-content text-sm font-light leading-4 text-tertiary-200;
}
