@tailwind base;
@tailwind components;
@tailwind utilities;

.copy {
  font-size: 14px;
  font-weight: 400;
  line-height: inherit;

  @apply text-quaternary-500
}